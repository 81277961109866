.cont-text {
  font-size: 20px;
  width: 75% !important;
}
.contact-box {
  height: 90vh;
}
.cont-row {
  top: 100px;
}

@media screen and (max-width: 768px) {
  .cont-text {
    font-size: 14px;
    width: 100% !important;
  }
  .contact-box {
    height: 40%;
    bottom: 0;
  }
  .blank {
    height: 50%;
  }
  .cont-row {
    top: 75px;
  }
}
