@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

.cs-heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 60px;
  color: #333;
  -webkit-text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  -moz-text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.6), 0 0 2px rgba(0, 0, 0, 0.7);
  word-spacing: 16px;
  margin-bottom: 20px;
}

.cs-para {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  color: #666;
  text-shadow: 0 0 40px #FFFFFF, 0 0 30px #FFFFFF, 0 0 20px #FFFFFF;
}

.cs-container {
  height: 90vh;
  width: 100%;
  background-color: #eee;
}

.cs-wrapper {
  width: 100%;
  min-height: 100%;
  height: auto;
  display: table;
}

.cs-content {
  display: table-cell;
  vertical-align: middle;
}

.cs-item {
  width: auto;
  height: auto;
  margin: 0 auto;
  text-align: center;
  padding: 8px;
}

@media only screen and (max-width: 800px) {
  .cs-heading {
    font-size: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .cs-heading {
    font-size: 30px;
  }
}