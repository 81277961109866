.interestedButton {
    background-color: white;
    border-radius: 4px;
    font-family: poppins;
    font-size: 14px;
    height: 40px;
    width: 160px;

    outline: none;
    border: none;

    // for webkit browsers
    color: #000000;
}

.mainContainer {
    padding: 24px 12vw;
    height: 100px;
}

.menu {
    font-size: 16px;
}

.burger {
    font-size: 30px;
}

// ipad
@media only screen and (max-width: 820px) {
    .mainContainer {
        padding: 40px !important;
        height: 100px !important;
    }

    .menu {
        font-size: 12px !important;
    }

    .burger {
        font-size: 20px !important;
    }

    .interestedButton {
        width: 120px;
        height: 30px;
        font-size: 12px;
    }

    .fons {
        font-size: 12px;
    }
}

.interestedModalDialog {
    width: 889px !important;
    max-width: 95% !important;
}

.interestedModalContent {
    border-radius: 25px !important;
}

.formGroup {
    background-color: #ebebeb !important;
    flex: 1;
    border-radius: 14px;
    padding: 8px 20px;
    font-family: poppins;
}

.formLabel {
    font-size: 12px;
    color: #777777;
}

.formControl {
    border: none !important;
    background-color: transparent !important;
    font-size: 16px !important;
    padding: 0 !important;

    *:focus {
        outline: none !important;
    }
}

@media only screen and (max-width: 600px) {
    .mainContainer {
        padding: 16px !important;
        height: 75px !important;
    }
}
