.line1 {
  position: absolute;
  left: -12%;
  top: 15%;
  width: 50%;
}
.line2 {
  position: absolute;
  right: -13%;
  top: 14.5%;
  width: 53%;
}
.line3 {
  position: absolute;
  bottom: 34%;
  left: -12%;
  width: 34%;
}
.line4 {
  position: absolute;
  bottom: 13%;
  right: -12%;
  width: 36%;
}
@media screen and (max-width: 1200px) {
  .line1,
  .line2,
  .line3,
  .line4 {
    display: none;
  }
}
