@media only screen and (max-width: 1200px) {
    .seventhSectionHeading {
        text-align: center;
    }
    .seventhSectionText {
        text-align: center;
        font-size: 14px !important;
        max-width: 90vw !important;
        margin-inline: auto !important;
    }
}
