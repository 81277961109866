@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,600;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.poppins-reg {
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.cur {
  cursor: pointer;
}

.quicksand-reg {
  font-family: "Quicksand", sans-serif;
  font-weight: normal;
}

.quicksand-bold {
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
}

@font-face {
  font-family: "aerospace";
  src: url("./fonts/Aerospace.woff") format("woff");
}

a {
  text-decoration: none !important;
}

.sectionContainer {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main-box {
  padding-left: 12vw;
  padding-right: 12vw;
  width: 100%;
}

.p {
  font-size: 18px;
  font-family: Quicksand;
}

.h1 {
  font-size: 48px !important;
  font-family: Poppins;
}

.h2 {
  font-size: 40px !important;
  font-family: Poppins;
}

.h3 {
  font-size: 32px !important;
  font-family: Poppins;
}

.h4 {
  font-size: 28px !important;
  font-family: Poppins;
}

.h5 {
  font-size: 24px !important;
  font-family: Quicksand;
}

.h6 {
  font-size: 20px !important;
  font-family: Quicksand;
}

.small {
  font-size: 14px;
  font-family: Quicksand;
}

@media only screen and (max-width: 820px) {
  .main-box {
    padding-left: 40px;
    padding-right: 40px;
  }

  .h1 {
    font-size: 40px !important;
  }

  .h2 {
    font-size: 32px !important;
  }

  .h3 {
    font-size: 28px !important;
  }

  .p {
    font-size: 16px;
  }

  .h4 {
    font-size: 24px !important;
  }

  .h5 {
    font-size: 20px !important;
  }

  .h6 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 600px) {
  .main-box {
    padding-left: 16px;
    padding-right: 16px;
  }

  .p {
    font-size: 14px;
  }

  .h1 {
    font-size: 32px !important;
  }

  .h2 {
    font-size: 28px !important;
  }

  .h3 {
    font-size: 24px !important;
  }

  .h4 {
    font-size: 20px !important;
  }

  .h5 {
    font-size: 18px !important;
  }

  .h6 {
    font-size: 16px !important;
  }
}

.pressormedia {
  background-image: url(../public/assets/Repainting.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}