.searchInput {
  &:focus {
    border: none;
    outline: none;
  }
}

.customButton {
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  font-family: poppins;
  font-size: 14px;

  background-color: transparent;

  min-width: 178px;
  min-height: 40px;
}

.title {
  font-size: 60px;
  padding-block: 150px;
}

@media screen and (max-width: 480px) {
  .title{
    font-size: 40px;
    padding-block: 100px;
  }
  .customButton{
    min-width: 100px;
  }

}