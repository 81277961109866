.introText {
    font-size: 18px !important;
}

.firstSectionContainer {
    justify-content: center;
    height: 90vh;
}

.firstSectionText1 {
    font-size: 48px !important;
    letter-spacing: 0.48px;
}

.firstSectionText2 {
    font-size: 96px !important;
    letter-spacing: 0.48px;
}

.secondSectionImage {
    width: 740px;
}
.secondSectionText {
    font-size: 32px !important;
    width: 600px;
}
.heroBackground {
    width: 140%;
}

.thirdSectionContainer {
    background-image: url('../../../public/assets/Bridge.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.thirdSectionText {
    font-size: 32px;
}

.fourthSectionImage {
    width: 760px;
}

.fourthSectionText {
    font-size: 32px !important;
    width: 680px;
}

.fifthSectionImage {
    margin-top: -22vh;
}

.fifthSectionText {
    font-size: 32px !important;
    margin: 4vh 0 0 0 !important;
}

.sixthSectionContainer {
    left: 0 !important;
    top: 45vh !important;
}

.sixthSectionRight {
    img {
        width: 100%;
    }
}

.seventhSectionContainer {
    margin-top: 300px;
}

.seventhSectionLeft {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    width: 350px !important;
    height: 450px !important;
    text-align: right;
}
.seventhSectionCenter {
    width: 45%;
    position: relative;
}
.seventhSectionRight {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    width: 350px !important;
    height: 450px !important;
}

@media only screen and (max-width: 1400px) {
    .introText {
        font-size: 16px !important;
    }

    .secondSectionImage {
        width: 540px;
    }

    .secondSectionText {
        font-size: 24px !important;
        width: 400px;
    }

    .fourthSectionImage {
        width: 600px;
    }

    .fourthSectionText {
        font-size: 24px !important;
        width: 540px;
    }

    .fifthSectionImage {
        margin-top: -8vh;
    }
}

@media only screen and (max-width: 1200px) {
    .fifthSectionText {
        font-size: 32px !important;
        margin: 2vh 0 8vh 0 !important;
    }
    .fifthSectionImage {
        font-size: 32px !important;
        margin-top: -100px;
    }
    .introText {
        font-size: 14px !important;
    }

    .sixthSectionContainer {
        top: 35vh !important;
    }

    .sixthSectionLeft {
        width: 100% !important;
        align-items: start !important;
    }

    .sixthSectionHeading {
        font-size: 84px !important;
    }

    .sixthSectionText {
        font-size: 18px !important;
    }

    .sixthSectionRight {
        width: 100% !important;
        align-items: end !important;
        margin-bottom: 20px !important;

        img {
            width: 80%;
        }
    }
    .seventhSectionContainer {
        flex-direction: column !important;
        margin-top: 150px;
    }
    .seventhSectionImage {
        width: 100% !important;
        max-width: 600px;
    }

    .seventhSectionHeading {
        text-align: center;
    }

    .seventhSectionText {
        text-align: center;
        font-size: 14px !important;
        max-width: 90vw !important;
        margin-inline: auto !important;
    }
    .seventhSectionLeft {
        height: 300px !important;
        width: 100% !important;
    }
    .seventhSectionCenter {
        height: 400px !important;
        width: 50% !important;
    }
    .seventhSectionRight {
        height: 300px !important;
        width: 100% !important;
    }
}

@media only screen and (max-width: 1000px) {
    .secondSectionContainer {
        flex-direction: column;
        justify-content: space-evenly !important;
        padding: 0% !important;
    }
    .secondSectionText {
        padding: 0 10px;
    }
    .firstSectionText1 {
        font-size: 32px !important;
    }

    .firstSectionText2 {
        font-size: 64px !important;
    }

    .secondSectionImage {
        width: 80% !important;
    }

    .secondSectionText {
        text-align: center;
    }

    .fourthSectionContainer {
        flex-direction: column-reverse;
        justify-content: space-around !important;
    }

    .fourthSectionImage {
        width: 100% !important;
    }

    .fourthSectionText {
        text-align: center;
        width: 90% !important;
    }

    .fifthSectionImage {
        margin-top: -8vh;
        width: 90%;
    }

    .sixthSectionContainer {
        top: 30vh !important;
    }

    .eightSectionlowerHeading {
        font-size: 38px !important;
    }

    .eightSectionlowerText {
        font-size: 20px !important;
    }
}

// ipad
@media only screen and (max-width: 820px) {
    .firstSectionContainer {
        justify-content: center !important;
        gap: 15vh !important;
        height: 92vh;
    }

    .thirdSectionContainer {
        background-image: url('../../../public/assets/Bridge-mobile.webp');
    }

    .thirdSectionImageContainer {
        text-align: center !important;
        width: 100% !important;
        height: 50% !important;

        p {
            font-size: 24px !important;
        }
    }
    .fifthSectionContainer {
        margin-top: 500px !important;
    }
    .fifthSectionImage {
        margin-top: -100px;
    }

    .fifthSectionText {
        font-size: 24px !important;
    }

    .sixthSectionContainer {
        top: 35vh !important;
    }

    .sixthSectionHeading {
        font-size: 64px !important;
        line-height: 84px !important;
    }

    .sixthSectionText {
        font-size: 16px !important;
    }
    .seventhSectionCenter {
        height: 400px !important;
        width: 70% !important;
    }
}

// mobile
@media only screen and (max-width: 600px) {
    .introText {
        font-size: 12px !important;
    }

    .firstSectionText1 {
        font-family: poppins;
        font-size: 20px !important;
    }

    .firstSectionText2 {
        font-size: 10vw !important;
    }

    .secondSectionText {
        font-size: 18px !important;
        width: 80%;
        text-align: center;
    }
    .thirdSectionText {
        padding: 0 40px;
    }
    .fifthSectionImage {
        margin-top: -45px;
    }

    .sixthSectionHeading {
        font-size: 48px !important;
        line-height: 64px !important;
    }

    .sixthSectionText {
        font-size: 12px !important;
    }

    .seventhSectionContainer {
        margin-top: 0;
    }
    .seventhSectionLeft {
        height: 380px !important;
        width: 100% !important;
    }
    .seventhSectionCenter {
        display: flex;
        align-items: center;
        height: 280px !important;
        width: 70% !important;
    }
    .seventhSectionRight {
        height: 300px !important;
        width: 100% !important;
    }

    .eightSectionlowerHeading {
        font-size: 28px !important;
    }

    .eightSectionlowerText {
        font-size: 14px !important;
    }
}
